import NextLink from 'next/link';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { ProfileAvatar, Rating } from '@care/react-component-lib';
import getUserInitials from '@/utils/getUserInitials';
import { getSimilarCaregivers_seoSimilarCaregiverProfiles_SEOSimilarCaregiverProfilesSuccess_similarCaregiverProfiles_caregiverList as CaregiverList } from '@/__generated__/getSimilarCaregivers';

const SimilarCaregivers = ({ profiles }: { profiles: CaregiverList[] }) => {
  const profilesToDisplay = profiles.filter((profile) => profile.seoProfileURL).slice(0, 5);

  return (
    <Container maxWidth="md" disableGutters>
      <Box mb={3}>
        <Typography variant="h2">Similar caregivers nearby</Typography>
      </Box>
      <Grid container columnGap={{ xs: 2, md: 4 }} rowGap={2}>
        {profilesToDisplay.map((profile) => (
          <Grid item key={profile.member.displayName}>
            <NextLink href={profile.seoProfileURL as string}>
              <Link href={profile.seoProfileURL as string} underline="none">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  data-testid="similar-profiles"
                  px={1.5}
                  rowGap={0.5}>
                  <ProfileAvatar
                    alt={profile.member.displayName}
                    src={profile.member.imageURL ?? undefined}
                    size="xLarge"
                    mb={1}>
                    {getUserInitials(profile.member.displayName)}
                  </ProfileAvatar>
                  <Typography variant="h4" color="primary">
                    {profile.member.displayName}
                  </Typography>
                  <Rating
                    name="size-large"
                    size="small"
                    defaultValue={profile.avgReviewRating ?? undefined}
                    readOnly
                  />
                  <Typography variant="info1" color="secondary">
                    {profile.member.address?.city}
                  </Typography>
                </Box>
              </Link>
            </NextLink>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export { SimilarCaregivers };
