import { Grid, Box, Typography } from '@mui/material';
import mapQualitiesAndServices from '@/utils/mapQualitiesAndServices';
import payRangeHelper from '@/utils/payRangeHelper';
import {
  getCaregiver_getCaregiver_profiles_commonCaregiverProfile_merchandizedJobInterests as MerchandizedJobInterestsType,
  getCaregiver_getCaregiver_profiles_houseKeepingCaregiverProfile as HousekeepingProfileType,
} from '@/__generated__/getCaregiver';
import { CanHelpWith } from './CanHelpWith';
import { ServicesOffered } from './ServicesOffered';

type HousekeepingServicesProps = {
  housekeepingProfile: HousekeepingProfileType;
  merchandizedJobInterests: MerchandizedJobInterestsType;
  firstName: string;
};

const HousekeepingServices = ({
  housekeepingProfile,
  merchandizedJobInterests,
  firstName,
}: HousekeepingServicesProps) => {
  const { supportedServices, recurringRate } = housekeepingProfile;
  const renderedCanHelpWith = mapQualitiesAndServices({
    ...merchandizedJobInterests,
  }).sort();

  const toRate = recurringRate?.hourlyRateTo?.amount || '0';
  const fromRate = recurringRate?.hourlyRateFrom?.amount || '0';

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={7}>
        <ServicesOffered firstName={firstName} supportedServices={supportedServices} />
        <Box mt={3} mb={{ xs: 3, sm: 0 }} maxWidth={326}>
          <Typography variant="h4" component="p" pb={1}>
            Rates
          </Typography>
          {!fromRate && !toRate ? (
            <Box>{firstName} hasn&apos;t provided this information.</Box>
          ) : (
            <Box>
              {(Number(fromRate) > 0 || Number(toRate) > 0) && (
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">Recurring jobs</Typography>
                  <Typography variant="h4" component="p">
                    {payRangeHelper(toRate, fromRate)}/hr
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <CanHelpWith firstName={firstName} canHelpWithList={renderedCanHelpWith} />
    </Grid>
  );
};

export { HousekeepingServices };
