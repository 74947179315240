import { AttributeList } from '@care/caregiver-profile-components';
import { Grid } from '@mui/material';
import { Icon24UtilityCheckmark } from '@care/react-icons';

type CanHelpWithProps = {
  firstName: string;
  canHelpWithList: string[];
};

const CanHelpWith = ({ firstName, canHelpWithList }: CanHelpWithProps) => {
  return (
    <Grid item xs={12} sm={5}>
      {canHelpWithList.length === 0 ? (
        <AttributeList
          label="Can help with"
          content={`${firstName} hasn't provided this information.`}
        />
      ) : (
        <AttributeList
          label="Can help with"
          content={canHelpWithList}
          icon={<Icon24UtilityCheckmark />}
        />
      )}
    </Grid>
  );
};

export { CanHelpWith };
