import { useMediaQuery, Theme } from '@mui/material';
import { getCaregiver_getCaregiver_member as MemberType } from '@/__generated__/getCaregiver';
import { AboutCaregiver, AboutCaregiverProps } from '../AboutCaregiver';
import { Section } from '../Section';

interface AboutCaregiverSectionProps extends AboutCaregiverProps {
  firstName: MemberType['firstName'];
}

const AboutCaregiverSection = ({
  firstName,
  experienceSummary,
  isVaccinated,
  yearsOfExperience,
  isAiAssistedBio,
}: AboutCaregiverSectionProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Section
      displayDivider={!isDesktop}
      title={`About ${firstName}`}
      useH1Variant
      headerBottomMargin={1}>
      <AboutCaregiver
        isVaccinated={isVaccinated}
        yearsOfExperience={yearsOfExperience}
        experienceSummary={experienceSummary}
        isAiAssistedBio={isAiAssistedBio}
      />
    </Section>
  );
};

export { AboutCaregiverSection };
