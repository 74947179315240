import { Button } from '@mui/material';
import { AmplitudeEvent, trackAmplitudeEvent } from '@/lib/analyticsHelper';
import { getProviderPublicId_provider_ProviderLookupSuccess_provider as getProviderPublicIdType } from '@/__generated__/getProviderPublicId';
import { ReviewInfoCareType } from '@/__generated__/globalTypes';
import useReviewShortLink from '../hooks/useReviewShortLink';

export interface WriteReviewButtonProps {
  publicId: getProviderPublicIdType['publicId'];
  serviceShortName: ReviewInfoCareType;
}

const WriteReviewButton = ({ publicId, serviceShortName }: WriteReviewButtonProps) => {
  const shortUrl = useReviewShortLink({ publicId, serviceShortName });

  if (!shortUrl) {
    return null;
  }

  const handleRequestReview = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const amplitudeData = {
      cta_text: (e.target as HTMLElement).textContent as string,
      cta_type: 'button',
    };

    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, amplitudeData);
  };

  return (
    <div>
      <Button
        color="secondary"
        variant="outlined"
        onClick={handleRequestReview}
        href={shortUrl}
        component="a"
        rel="nofollow">
        Write a review
      </Button>
    </div>
  );
};

export default WriteReviewButton;
