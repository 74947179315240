import Availability, { AvailabilityProps } from '../Availability';
import { Section } from '../Section';

const AvailabilitySection = ({ firstName, recurringAvailability }: AvailabilityProps) => {
  return (
    <Section displayDivider title={`Contact ${firstName} to check their availability`}>
      <Availability firstName={firstName} recurringAvailability={recurringAvailability} />
    </Section>
  );
};

export default AvailabilitySection;
