import { Grid, Box, Link, Typography } from '@mui/material';
import {
  SafetyCard,
  SafetyCardCTAType,
  BackgroundCheckType,
} from '@care/caregiver-profile-components';
import { Icon24InfoBgcFilled } from '@care/react-icons';
import { getCaregiver_getCaregiver as CaregiverType } from '@/__generated__/getCaregiver';

export interface SafetyProps {
  hasCareCheck: CaregiverType['hasCareCheck'];
}

const Safety = ({ hasCareCheck }: SafetyProps) => {
  const careCheckText = "What's included in CareCheck?";

  return (
    <Grid item xs={12}>
      <Box mb={{ xs: 2, md: 3 }}>
        <Typography variant="body2">
          Visit our
          <Link href="https://www.care.com/about/safety/" target="_blank" variant="body2">
            {`${' '}Safety Center${' '}`}
          </Link>
          to learn more.
          <Link
            href="https://help.care.com/families/s/article/What-is-a-CareCheck-1?language=en_US"
            target="_blank"
            variant="body2">
            {`${' '}${careCheckText}`}
          </Link>
        </Typography>
      </Box>
      <Grid item xs={12} sm={9} md={11}>
        {hasCareCheck && (
          <SafetyCard
            icon={<Icon24InfoBgcFilled />}
            onButtonClick={() => {}}
            title="CareCheck"
            ctaType={SafetyCardCTAType.completed}
            careCheckType={BackgroundCheckType.CARE_CHECK}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Safety;
