import { AttributeList } from '@care/caregiver-profile-components';
import { Grid, Box, Typography } from '@mui/material';
import { Icon24UtilityCheckmark } from '@care/react-icons';
import mapQualitiesAndServices from '@/utils/mapQualitiesAndServices';
import payRangeHelper from '@/utils/payRangeHelper';
import {
  getCaregiver_getCaregiver_profiles_petCareCaregiverProfile as PetCareProfileType,
  getCaregiver_getCaregiver_profiles_petCareCaregiverProfile_rates as RateType,
  getCaregiver_getCaregiver_profiles_commonCaregiverProfile_merchandizedJobInterests as MerchandizedJobInterestsType,
} from '@/__generated__/getCaregiver';
import { CanHelpWith } from './CanHelpWith';
import { ServicesOffered } from './ServicesOffered';

type PetCareServicesProps = {
  petCareProfile: PetCareProfileType;
  merchandizedJobInterests: MerchandizedJobInterestsType;
  firstName: string;
};

const PetCareServices = ({
  petCareProfile,
  merchandizedJobInterests,
  firstName,
}: PetCareServicesProps) => {
  const { petSpecies, supportedServices, recurringRate, rates } = petCareProfile;
  const renderedSpecies = mapQualitiesAndServices({
    ...petSpecies,
  }).sort();
  const renderedCanHelpWith = mapQualitiesAndServices({
    ...merchandizedJobInterests,
  }).sort();

  const toRate = recurringRate?.hourlyRateTo?.amount || '0';
  const fromRate = recurringRate?.hourlyRateFrom?.amount || '0';

  const rateDisplayText = (period: 'hr' | 'night' | 'day' | 'walk') => {
    return `${payRangeHelper(toRate, fromRate)}/${period}`;
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={7}>
        <Box>
          {renderedSpecies.length === 0 ? (
            <AttributeList
              label="Pets"
              content={`Message ${firstName} to get details about their experience.`}
            />
          ) : (
            <AttributeList
              label="Pets"
              content={renderedSpecies}
              icon={<Icon24UtilityCheckmark />}
            />
          )}
        </Box>
        <Box mt={3}>
          <ServicesOffered firstName={firstName} supportedServices={supportedServices} />
        </Box>
        <Box mt={3} mb={{ xs: 3, sm: 0 }} maxWidth={326}>
          <Typography variant="h4" component="p" mb={1}>
            Rates
          </Typography>
          {!fromRate && !toRate && rates.length === 0 ? (
            <Box>{firstName} hasn&apos;t provided this information.</Box>
          ) : (
            <Box>
              {(Number(fromRate) > 0 || Number(toRate) > 0) && (
                <Box display="flex" justifyContent="space-between" pb={1}>
                  <Typography variant="body2">Recurring jobs</Typography>
                  <Typography variant="h4" component="p">
                    {rateDisplayText('hr')}
                  </Typography>
                </Box>
              )}
              {rates.map((rate: RateType) => (
                <Box
                  key={`${rate.activity} - ${rate.activityRateUnit}`}
                  display="flex"
                  justifyContent="space-between"
                  sx={{ ':not(:last-child)': { pb: 1 } }}>
                  <Typography variant="body2">{rate.activity}</Typography>
                  <Typography variant="h4" component="p">
                    ${rate.activityRate.amount}/{rate.activityRateUnit}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Grid>
      <CanHelpWith firstName={firstName} canHelpWithList={renderedCanHelpWith} />
    </Grid>
  );
};

export { PetCareServices };
