import { Box, Grid, Typography } from '@mui/material';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { getCaregiver_getCaregiver_recurringAvailability as RecurringAvailabilityType } from '@/__generated__/getCaregiver';

export type AvailabilityProps = {
  firstName: string;
  recurringAvailability: RecurringAvailabilityType;
};

/**
 * Simplification of GraphQL generated interfaces, replacing each day in recurringAvailability
 */
interface SingleDay {
  blocks: LocalTimeBlock[];
}

/**
 * Simplification of GraphQL generated interfaces,
 * replacing each timeBlock in any day of recurringAvailability
 */
interface LocalTimeBlock {
  end: globalScalarLocalTime;
  start: globalScalarLocalTime;
}

const Availability = ({ firstName, recurringAvailability }: AvailabilityProps) => {
  dayjs.extend(customParseFormat);

  const schedule: { day: string; timeRange: string }[] = [];
  if (recurringAvailability.dayList) {
    // Cast into map, so each day does not have to be used as a separate type, makes it iterable
    const dayList = new Map<string, SingleDay>(Object.entries(recurringAvailability?.dayList));

    dayList.forEach((singleDay, dayName) => {
      const dayRanges: string[] = [];
      singleDay?.blocks?.forEach((block) => {
        const start = dayjs(block.start, 'HH:mm:ss').format('h:mm A');
        const end = dayjs(block.end, 'HH:mm:ss').format('h:mm A');
        dayRanges.push(`${start}–${end}`);
      });
      if (dayRanges.length > 0) {
        schedule.push({
          day: dayName.slice(0, 3),
          // Delimiter for multiple time ranges
          timeRange: dayRanges.join(', '),
        });
      }
    });
  }

  return (
    <Grid item xs={12} data-testid="availability-container">
      {schedule.length > 0 ? (
        schedule.map(({ timeRange, day }) => (
          <Box key={day} display="flex" mb={2} data-testid={`${day}-schedule`}>
            <Typography variant="h4" width="48px" textTransform="capitalize">
              {day}
            </Typography>
            <Typography variant="body2" ml={2}>
              {timeRange}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography variant="body2">Send {firstName} a message.</Typography>
      )}
    </Grid>
  );
};

export default Availability;
