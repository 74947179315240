import { Qualifications, QualificationsProps } from '../Qualifications';
import { Section } from '../Section';

const QualificationsSection = ({
  educationDegrees,
  firstName,
  languages,
  activeProfile,
}: QualificationsProps) => {
  return (
    <Section title="Qualifications" displayDivider>
      <Qualifications
        activeProfile={activeProfile}
        firstName={firstName}
        educationDegrees={educationDegrees}
        languages={languages}
      />
    </Section>
  );
};

export default QualificationsSection;
