import { Box, Container, Grid } from '@mui/material';
import CaregiverZipCodeCTA from '@/components/CaregiverZipCodeCTA';

interface ZipCodeCTAProps {
  enrollmentURL: string;
  serviceShortName: string;
  areBreadcrumbsPresent: boolean;
}

const ZipCodeCTASection = ({
  enrollmentURL,
  serviceShortName,
  areBreadcrumbsPresent,
}: ZipCodeCTAProps) => {
  const desktopMarginTop = areBreadcrumbsPresent ? -4 : 0;
  return (
    <Box mt={{ xs: 0, sm: desktopMarginTop }} mb={{ xs: 0, sm: 3 }} component="section">
      <Container maxWidth="md" disableGutters>
        <Grid container>
          <Grid item xs={12}>
            <CaregiverZipCodeCTA
              enrollmentURL={enrollmentURL}
              serviceShortName={serviceShortName}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ZipCodeCTASection;
