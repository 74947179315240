import { Typography } from '@mui/material';
import WriteReviewButton from '@/components/CaregiverProfile/WriteReviewButton';
import { ProviderPublicIdType } from '@/types/providerSegmentation';
import { getCaregiver_getCaregiver_member as MemberType } from '@/__generated__/getCaregiver';
import { ReviewInfoCareType } from '@/__generated__/globalTypes';
import { MostRecentReview, MostRecentReviewProps } from '../MostRecentReview';
import { Section } from '../Section';

interface MostRecentReviewSectionProps extends MostRecentReviewProps {
  publicId: ProviderPublicIdType['publicId'];
  serviceShortName: ReviewInfoCareType;
  firstName: MemberType['firstName'];
}

const MostRecentReviewSection = ({
  reviews,
  averageRatings,
  totalReviews,
  setOpenModal,
  openModal,
  publicId,
  serviceShortName,
  firstName,
}: MostRecentReviewSectionProps) => {
  return (
    <Section
      displayDivider
      title="Reviews"
      additionalComponent={
        publicId && <WriteReviewButton publicId={publicId} serviceShortName={serviceShortName} />
      }>
      {totalReviews ? (
        <MostRecentReview
          reviews={reviews}
          averageRatings={averageRatings}
          totalReviews={totalReviews}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      ) : (
        <Typography variant="body2">Be the first to write {firstName} a review!</Typography>
      )}
    </Section>
  );
};

export { MostRecentReviewSection };
