import { AttributeList } from '@care/caregiver-profile-components';
import { Box } from '@mui/material';
import { Icon24UtilityCheckmark } from '@care/react-icons';
import mapQualitiesAndServices from '@/utils/mapQualitiesAndServices';
import { ActiveProfile } from '@/types/common';

type ServicesOfferedProps = {
  firstName: string;
  supportedServices: ActiveProfile['supportedServices'];
};

const ServicesOffered = ({ firstName, supportedServices }: ServicesOfferedProps) => {
  const renderedServices = mapQualitiesAndServices({
    ...supportedServices,
  }).sort();

  return (
    <Box>
      {renderedServices.length === 0 ? (
        <AttributeList
          label="Services offered"
          content={`${firstName} hasn't provided this information.`}
        />
      ) : (
        <AttributeList
          label="Services offered"
          content={renderedServices}
          icon={<Icon24UtilityCheckmark />}
        />
      )}
    </Box>
  );
};

export { ServicesOffered };
