import { Box, Container, useMediaQuery, Theme } from '@mui/material';
import Breadcrumbs, { BreadcrumbsProp } from '@/components/Breadcrumbs';

const BreadcrumbsSection = ({ breadcrumbs }: BreadcrumbsProp) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Box component="section">
      <Box pt={{ xs: 1, sm: 3 }} mb={{ xs: 1, sm: 3 }}>
        <Container maxWidth="md" disableGutters={isDesktop}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Container>
      </Box>
    </Box>
  );
};

export default BreadcrumbsSection;
