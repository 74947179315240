import { useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { useMutation } from '@apollo/client';
import { GET_REVIEW_SHORT_LINK_CREATE } from '@/API/caregiverDetail';
import {
  reviewShortLinkCreate as reviewShortLinkCreateType,
  reviewShortLinkCreateVariables,
} from '@/__generated__/reviewShortLinkCreate';
import { getProviderPublicId_provider_ProviderLookupSuccess_provider as getProviderPublicIdType } from '@/__generated__/getProviderPublicId';
import { ReviewInfoCareType } from '@/__generated__/globalTypes';

export interface ReviewShortLinkProps {
  publicId: getProviderPublicIdType['publicId'];
  serviceShortName: ReviewInfoCareType;
}

const useReviewShortLink = ({ publicId, serviceShortName }: ReviewShortLinkProps) => {
  const [shortURL, setShortURL] = useState('');
  const [reviewShortURL] = useMutation<reviewShortLinkCreateType, reviewShortLinkCreateVariables>(
    GET_REVIEW_SHORT_LINK_CREATE
  );

  useEffect(() => {
    const fetchShortUrl = async () => {
      try {
        const providerCareType = serviceShortName || ReviewInfoCareType.CHILD_CARE;

        const { data } = await reviewShortURL({
          variables: {
            input: {
              providerCareType,
              publicId,
              refer: 'rar',
            },
          },
        });

        if (data && data.reviewShortLinkCreate.__typename === 'ReviewShortLinkCreatePayload') {
          setShortURL(data.reviewShortLinkCreate.shortUrl);
        }
      } catch (error) {
        captureException(error);
      }
    };

    fetchShortUrl();
  }, [reviewShortURL, publicId, serviceShortName]);

  return shortURL;
};

export default useReviewShortLink;
