import Safety, { SafetyProps } from '../Safety';
import { Section } from '../Section';

const SafetySection = ({ hasCareCheck }: SafetyProps) => {
  return (
    <Section displayDivider title="Safety">
      <Safety hasCareCheck={hasCareCheck} />
    </Section>
  );
};

export default SafetySection;
