import { Container, Box } from '@mui/material';
import { JoinToContact } from '../JoinToContact';

interface JoinToContactSectionProps {
  ctaText: string;
  enrollmentURL: string;
}

const JoinToContactSection = ({ ctaText, enrollmentURL }: JoinToContactSectionProps) => {
  return (
    <Box
      width="100%"
      component="section"
      py={3}
      position="fixed"
      bottom={0}
      zIndex={2}
      sx={{
        background:
          'var(--background-white-gradient, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.65) 24.48%, #FFF 50%, #FFF 100%))',
      }}>
      <Container maxWidth="md">
        <Box maxWidth="300px" mx="auto">
          <JoinToContact ctaText={ctaText} enrollmentURL={enrollmentURL} />
        </Box>
      </Container>
    </Box>
  );
};

export { JoinToContactSection };
