import { AttributeList } from '@care/caregiver-profile-components';
import { Grid, Box, useMediaQuery, Theme } from '@mui/material';
import { Icon24UtilityCheckmark } from '@care/react-icons';
import {
  getCaregiver_getCaregiver as CaregiverType,
  getCaregiver_getCaregiver_educationDegrees as EducationDegreeType,
} from '@/__generated__/getCaregiver';
import mapQualitiesAndServices from '@/utils/mapQualitiesAndServices';
import { Language } from '@/__generated__/globalTypes';
import { ActiveProfile } from '@/types/common';
import { ADDITIONAL_DETAILS } from '@/constants';

export interface QualificationsProps {
  activeProfile: ActiveProfile;
  educationDegrees: CaregiverType['educationDegrees'];
  firstName: string;
  languages: CaregiverType['member']['languages'];
}

const Qualifications = ({
  activeProfile,
  educationDegrees,
  firstName,
  languages,
}: QualificationsProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getLanguages = (): string[] => {
    if (!languages) return [];

    return languages.reduce((languageList: string[], language: Language | null): string[] => {
      if (!language) return languageList;

      const formattedLanguage = language[0].toUpperCase() + language.slice(1).toLowerCase();
      languageList.push(formattedLanguage);
      return languageList;
    }, []);
  };

  const getEducation = (): string[] => {
    if (!educationDegrees) return [];

    return educationDegrees.reduce(
      (educationDetailsList: string[], educationDegree: EducationDegreeType | null): string[] => {
        if (!educationDegree) return educationDetailsList;

        return educationDetailsList.concat(educationDegree.educationDetailsText);
      },
      []
    );
  };

  const languageList = getLanguages();
  const educationList = getEducation();

  let qualities;
  let otherQualities;
  /* once all verticals are converted to explicitly listing 'additional details' and 'professional skills', we will switch to the 'new'
    variable method and remove the additional details extraction from qualities */
  let newAdditionalDetails = null;
  let newProfessionalSkills = null;

  if (activeProfile.qualities) {
    if (activeProfile.__typename === 'ChildCareCaregiverProfile') {
      qualities = activeProfile.qualities;
      otherQualities = activeProfile.otherQualities;
    } else if (activeProfile.__typename === 'PetCareCaregiverProfile') {
      qualities = activeProfile.qualities;
      otherQualities = activeProfile.otherQualities;
    } else if (activeProfile.__typename === 'SeniorCareCaregiverProfile') {
      qualities = activeProfile.qualities;
      otherQualities = activeProfile.otherQualities;
    } else if (activeProfile.__typename === 'HouseKeepingCaregiverProfile') {
      qualities = activeProfile.qualities;
      otherQualities = activeProfile.otherQualities;
    } else if (activeProfile.__typename === 'TutoringCaregiverProfile') {
      const newQualities = activeProfile.qualities;
      newAdditionalDetails = mapQualitiesAndServices(newQualities?.additionalDetails)?.sort();
      newProfessionalSkills = mapQualitiesAndServices(newQualities?.professionalSkills)?.sort();
      otherQualities = activeProfile.otherQualities;
    }
  }

  const renderedQualities: string[] = mapQualitiesAndServices(qualities);

  const additionalDetails =
    newAdditionalDetails ||
    renderedQualities.filter((quality) => ADDITIONAL_DETAILS.indexOf(quality) >= 0).sort();

  const professionalSkills =
    newProfessionalSkills ||
    renderedQualities.filter((quality) => ADDITIONAL_DETAILS.indexOf(quality) < 0).sort();

  return (
    <Grid item container xs={12} data-testid="qualification-section">
      <Grid container item xs={12} sm={7} direction="column">
        {educationList.length > 0 && (
          <Grid item>
            <Box mb={isMobile ? 2 : 3} data-testid="education-section">
              <AttributeList content={educationList} label="Education" />
            </Box>
          </Grid>
        )}
        {languageList.length > 0 && (
          <Grid item>
            <Box mb={{ xs: 3, sm: 0 }}>
              <AttributeList
                content={languageList}
                icon={<Icon24UtilityCheckmark />}
                label="Languages"
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={5}>
        {activeProfile.__typename !== 'HouseKeepingCaregiverProfile' &&
          (professionalSkills.length === 0 ? (
            <AttributeList
              label="Professional skills"
              content={`Message ${firstName} to get details about their experience.`}
            />
          ) : (
            <AttributeList
              label="Professional skills"
              content={professionalSkills}
              icon={<Icon24UtilityCheckmark />}
            />
          ))}
        {otherQualities && otherQualities.length > 0 && (
          <Box mt={activeProfile.__typename !== 'HouseKeepingCaregiverProfile' ? 3 : 0} mb={3}>
            <AttributeList label="Other Qualifications & Credentials" content={otherQualities} />
          </Box>
        )}
        <Box mt={activeProfile.__typename !== 'HouseKeepingCaregiverProfile' ? 3 : 0}>
          {additionalDetails.length === 0 ? (
            <AttributeList
              label="Additional details"
              content={`${firstName} hasn't provided this information.`}
            />
          ) : (
            <AttributeList
              label="Additional details"
              content={additionalDetails}
              icon={<Icon24UtilityCheckmark />}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export { Qualifications };
