import { getCaregiver_getCaregiver_profiles_commonCaregiverProfile_merchandizedJobInterests as MerchandizedJobInterestsType } from '@/__generated__/getCaregiver';
import { ActiveProfile } from '@/types/common';
import { ChildCareServices } from '../ChildCareServices';
import { PetCareServices } from '../PetCareServices';
import { SeniorCareServices } from '../SeniorCareServices';
import { Section } from '../Section';
import { TutoringServices } from '../TutoringServices';
import { HousekeepingServices } from '../HousekeepingServices';

interface ServicesSectionProps {
  activeProfile: ActiveProfile;
  merchandizedJobInterests: MerchandizedJobInterestsType;
  firstName: string;
}

const ServicesSection = ({
  activeProfile,
  merchandizedJobInterests,
  firstName,
}: ServicesSectionProps) => {
  return (
    <Section title="Services" displayDivider>
      {activeProfile.__typename === 'ChildCareCaregiverProfile' && (
        <ChildCareServices
          childCareProfile={activeProfile}
          merchandizedJobInterests={merchandizedJobInterests}
          firstName={firstName}
        />
      )}
      {activeProfile.__typename === 'PetCareCaregiverProfile' && (
        <PetCareServices
          petCareProfile={activeProfile}
          merchandizedJobInterests={merchandizedJobInterests}
          firstName={firstName}
        />
      )}
      {activeProfile.__typename === 'SeniorCareCaregiverProfile' && (
        <SeniorCareServices
          seniorCareProfile={activeProfile}
          merchandizedJobInterests={merchandizedJobInterests}
          firstName={firstName}
        />
      )}
      {activeProfile.__typename === 'TutoringCaregiverProfile' && (
        <TutoringServices
          tutoringProfile={activeProfile}
          merchandizedJobInterests={merchandizedJobInterests}
          firstName={firstName}
        />
      )}
      {activeProfile.__typename === 'HouseKeepingCaregiverProfile' && (
        <HousekeepingServices
          housekeepingProfile={activeProfile}
          merchandizedJobInterests={merchandizedJobInterests}
          firstName={firstName}
        />
      )}
    </Section>
  );
};

export { ServicesSection };
