// this is a hard-coded table rather than a dynamically generated display value because of the presence of a variety of acronyms
export const GQL_TUTORING_SUBJECTS_TABLE: Record<string, string> = {
  MATH_SUBJECT_ALGEBRA: 'Math - Algebra 1',
  MATH_SUBJECT_CALCULUS: 'Math - Calculus',
  MATH_SUBJECT_ELEMENTARY_SCHOOL_MATH: 'Math - Elementary School Math',
  MATH_SUBJECT_GEOMETRY: 'Math - Geometry',
  MATH_SUBJECT_HIGH_SCHOOL_MATH: 'Math - High School Math',
  MATH_SUBJECT_MIDDLE_SCHOOL_MATH: 'Math - Middle School Math',
  MATH_SUBJECT_PRE_CALCULUS: 'Math - Pre-Calculus',
  ENGLISH_SUBJECT_ESL: 'English - ESL',
  ENGLISH_SUBJECT_GRAMMAR: 'English - Grammar',
  ENGLISH_SUBJECT_LANGUAGE_ARTS_K6: 'English - Language Arts K-6',
  ENGLISH_SUBJECT_LITERATURE: 'English - Literature',
  ENGLISH_SUBJECT_READING: 'English - Reading',
  ENGLISH_SUBJECT_WRITING: 'English - Writing',
  SCIENCE_SUBJECT_BIOLOGY: 'Science - Biology',
  SCIENCE_SUBJECT_CHEMISTRY: 'Science - Chemistry',
  SCIENCE_SUBJECT_EARTH_SCIENCE: 'Science - Earth Science',
  SCIENCE_SUBJECT_ELEMENTARY_SCIENCE: 'Science - Elementary Science',
  SCIENCE_SUBJECT_LIFE_SCIENCE: 'Science - Life Science',
  SCIENCE_SUBJECT_MIDDLE_SCHOOL: 'Science - Middle School',
  SCIENCE_SUBJECT_PHYSICS: 'Science - Physics',
  SCIENCE_SUBJECT_PSYCHOLOGY: 'Science - Psychology',
  TEST_PREP_SUBJECT_3RD_GRADE_SOL: 'Test Prep - 3rd grade SOL',
  TEST_PREP_SUBJECT_5TH_GRADE_SOL: 'Test Prep - 5th grade SOL',
  TEST_PREP_SUBJECT_ACT: 'Test Prep - ACT',
  TEST_PREP_SUBJECT_ELEMENTARY_STANDARD_TESTING: 'Test Prep - Elementary Standard Testing',
  TEST_PREP_SUBJECT_GED: 'Test Prep - GED',
  TEST_PREP_SUBJECT_GMAT: 'Test Prep - GMAT',
  TEST_PREP_SUBJECT_GRE: 'Test Prep - GRE',
  TEST_PREP_SUBJECT_MIDDLE_SCHOOL_SOL: 'Test Prep - Middle School SOL',
  TEST_PREP_SUBJECT_PRIVATE_SCHOOL_ENTRANCE: 'Test Prep - Private School Entrance',
  TEST_PREP_SUBJECT_PSAT_TEST: 'Test Prep - PSAT test',
  TEST_PREP_SUBJECT_SAT_MATH: 'Test Prep - SAT Math',
  TEST_PREP_SUBJECT_SAT_VERBAL: 'Test Prep - SAT Verbal',
  TEST_PREP_SUBJECT_TOEFL: 'Test Prep - TOEFL',
  FOREIGN_LANGUAGE_SUBJECT_ARABIC: 'Foreign Language - Arabic',
  FOREIGN_LANGUAGE_SUBJECT_CHINESE: 'Foreign Language - Chinese',
  FOREIGN_LANGUAGE_SUBJECT_FRENCH: 'Foreign Language - French',
  FOREIGN_LANGUAGE_SUBJECT_GERMAN: 'Foreign Language - German',
  FOREIGN_LANGUAGE_SUBJECT_HINDI: 'Foreign Language - Hindi',
  FOREIGN_LANGUAGE_SUBJECT_ITALIAN: 'Foreign Language - Italian',
  FOREIGN_LANGUAGE_SUBJECT_JAPANESE: 'Foreign Language - Japanese',
  FOREIGN_LANGUAGE_SUBJECT_LATIN: 'Foreign Language - Latin',
  FOREIGN_LANGUAGE_SUBJECT_PORTUGUESE: 'Foreign Language - Portuguese',
  FOREIGN_LANGUAGE_SUBJECT_RUSSIAN: 'Foreign Language - Russian',
  FOREIGN_LANGUAGE_SUBJECT_SPANISH: 'Foreign Language - Spanish',
  COMPUTERS_SUBJECT_ANDROID_APP_DEVELOPMENT: 'Computers - Android App Development',
  COMPUTERS_SUBJECT_BASIC: 'Computers - Basic',
  COMPUTERS_SUBJECT_C: 'Computers - C',
  COMPUTERS_SUBJECT_CPP: 'Computers - C++',
  COMPUTERS_SUBJECT_COMPUTER_BASICS: 'Computers - Computer Basics',
  COMPUTERS_SUBJECT_COMPUTER_SCIENCE: 'Computers - Computer Science',
  COMPUTERS_SUBJECT_HTML: 'Computers - HTML',
  COMPUTERS_SUBJECT_INTERNET_SITE_DESIGN: 'Computers - Internet Site Design',
  COMPUTERS_SUBJECT_IOS_APP_DEVELOPMENT: 'Computers - iOS App Development',
  COMPUTERS_SUBJECT_JAVA: 'Computers - Java',
  COMPUTERS_SUBJECT_JAVASCRIPT: 'Computers - Javascript',
  COMPUTERS_SUBJECT_LINUX: 'Computers - Linux',
  COMPUTERS_SUBJECT_MACINTOSH: 'Computers - Macintosh',
  COMPUTERS_SUBJECT_MICROSOFT_ACCESS: 'Computers - Microsoft Access',
  COMPUTERS_SUBJECT_MICROSOFT_EXCEL: 'Computers - Microsoft Excel',
  COMPUTERS_SUBJECT_MICROSOFT_OFFICE: 'Computers - Microsoft Office',
  COMPUTERS_SUBJECT_MICROSOFT_POWERPOINT: 'Computers - Microsoft Powerpoint',
  COMPUTERS_SUBJECT_MICROSOFT_WINDOWS: 'Computers - Microsoft Windows',
  COMPUTERS_SUBJECT_MICROSOFT_WORD: 'Computers - Microsoft Word',
  COMPUTERS_SUBJECT_OUTLOOK: 'Computers - Outllook',
  COMPUTERS_SUBJECT_PHOTOSHOP: 'Computers - Photoshop',
  COMPUTERS_SUBJECT_PROGRAMMING: 'Computers - Programming',
  COMPUTERS_SUBJECT_PYTHON: 'Computers - Python',
  COMPUTERS_SUBJECT_VISUAL_BASIC: 'Computers - Visual Basic',
  COMPUTERS_SUBJECT_WINDOWS: 'Computers - Windows',
  ART_SUBJECT_ART_HISTORY: 'Art - Art History',
  ART_SUBJECT_DRAWING: 'Art - Drawing',
  ART_SUBJECT_PAINTING: 'Art - Painting',
  ART_SUBJECT_PHOTOGRAPHY: 'Art - Photography',
  MUSIC_DRAMA_SUBJECT_DRAMA_THEATER: 'Music Drama - Drama & Theater',
  MUSIC_DRAMA_SUBJECT_GENERAL_MUSIC: 'Music Drama - General Music',
  MUSIC_DRAMA_SUBJECT_SINGING: 'Music Drama - Singing',
  MUSICAL_INSTRUMENTS_SUBJECT_CLARINET: 'Musical Instruments - Clarinet',
  MUSICAL_INSTRUMENTS_SUBJECT_DRUMS: 'Musical Instruments - Drums',
  MUSICAL_INSTRUMENTS_SUBJECT_FLUTE: 'Musical Instruments - Flute',
  MUSICAL_INSTRUMENTS_SUBJECT_GUITAR: 'Musical Instruments - Guitar',
  MUSICAL_INSTRUMENTS_SUBJECT_KEYBOARD: 'Musical Instruments - Keyboard',
  MUSICAL_INSTRUMENTS_SUBJECT_PIANO: 'Musical Instruments - Piano',
  MUSICAL_INSTRUMENTS_SUBJECT_SAXOPHONE: 'Musical Instruments - Saxophone',
  MUSICAL_INSTRUMENTS_SUBJECT_TRUMPET: 'Musical Instruments - Trumpet',
  MUSICAL_INSTRUMENTS_SUBJECT_VIOLIN: 'Musical Instruments - Violin',
  DANCE_SUBJECT_BALLET: 'Dance - Ballet',
  DANCE_SUBJECT_HIP_HOP: 'Dance - Hip Hop',
  DANCE_SUBJECT_JAZZ: 'Dance - Jazz',
  SPORTS_FITNESS_SUBJECT_BASEBALL: 'Sports Fitness - Baseball',
  SPORTS_FITNESS_SUBJECT_BASKETBALL: 'Sports Fitness - Basketball',
  SPORTS_FITNESS_SUBJECT_FIELD_HOCKEY: 'Sports Fitness - Field Hockey',
  SPORTS_FITNESS_SUBJECT_FOOTBALL: 'Sports Fitness - Football',
  SPORTS_FITNESS_SUBJECT_GYMNASTICS: 'Sports Fitness - Gymnastics',
  SPORTS_FITNESS_SUBJECT_PERSONAL_TRAINING: 'Sports Fitness - Personal Training',
  SPORTS_FITNESS_SUBJECT_RUNNING: 'Sports Fitness - Running',
  SPORTS_FITNESS_SUBJECT_SOCCER: 'Sports Fitness - Soccer',
  SPORTS_FITNESS_SUBJECT_SOFTBALL: 'Sports Fitness - Softball',
  SPORTS_FITNESS_SUBJECT_SWIMMING: 'Sports Fitness - Swimming',
  SPORTS_FITNESS_SUBJECT_TENNIS: 'Sports Fitness - Tennis',
  SPORTS_FITNESS_SUBJECT_TRACK_AND_FIELD: 'Sports Fitness - Track & Field',
  SPORTS_FITNESS_SUBJECT_VOLLEYBALL: 'Sports Fitness - Volleyball',
  BUSINESS_SUBJECT_ACCOUNTING: 'Business - Accounting',
  BUSINESS_SUBJECT_ECONOMICS: 'Business - Economics',
  BUSINESS_SUBJECT_FINANCE: 'Business - Finance',
  BUSINESS_SUBJECT_MARKETING: 'Business - Marketing',
  BUSINESS_SUBJECT_ORGANIZATIONAL_BEHAVIOR: 'Business - Organizational Behavior',
  SPECIAL_EDUCATION_SUBJECT_ADD_ADHD: 'Special Education - ADD / ADHD',
  SPECIAL_EDUCATION_SUBJECT_DYSLEXIA: 'Special Education - Dyslexia',
  SPECIAL_EDUCATION_SUBJECT_EMOTIONAL_BEHAVIOR: 'Special Education - Emotional Behavior',
  SPECIAL_EDUCATION_SUBJECT_HEARING_IMPAIRED: 'Special Education - Hearing Impaired',
  SPECIAL_EDUCATION_SUBJECT_LEARNING_DISABLED: 'Special Education - Learning Disabled',
  SPECIAL_EDUCATION_SUBJECT_MILDLY_HANDICAPPED: 'Special Education - Mildly Handicapped',
};

export const convertSubjectsToStrings = (subjects: string[] | [] | null): string[] | [] => {
  if (!subjects) return [];

  const readableStrings = subjects.map((subject) => {
    return GQL_TUTORING_SUBJECTS_TABLE[subject];
  });

  return readableStrings;
};
