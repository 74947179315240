import { AttributeList } from '@care/caregiver-profile-components';
import { Grid, Box, Typography } from '@mui/material';
import { Icon24UtilityCheckmark } from '@care/react-icons';
import { convertSubjectsToStrings } from '@/utils/convertSubjectsToStrings';
import mapQualitiesAndServices from '@/utils/mapQualitiesAndServices';
import payRangeHelper from '@/utils/payRangeHelper';
import {
  getCaregiver_getCaregiver_profiles_tutoringCaregiverProfile as TutoringProfileType,
  getCaregiver_getCaregiver_profiles_commonCaregiverProfile_merchandizedJobInterests as MerchandizedJobInterestsType,
} from '@/__generated__/getCaregiver';
import { CanHelpWith } from './CanHelpWith';

type TutoringServicesProps = {
  tutoringProfile: TutoringProfileType;
  merchandizedJobInterests: MerchandizedJobInterestsType;
  firstName: string;
};

const TutoringServices = ({
  tutoringProfile,
  merchandizedJobInterests,
  firstName,
}: TutoringServicesProps) => {
  const {
    payRange,
    supportedServices,
    specificSubjects,
    otherSpecificSubject,
    otherGeneralSubjects = [],
  } = tutoringProfile;
  const convertedSubjects = convertSubjectsToStrings(specificSubjects).sort();

  const renderedSubjects = [...convertedSubjects, ...otherGeneralSubjects, otherSpecificSubject]
    .filter((element) => !!element)
    .sort() as string[];
  const renderedCanHelpWith = mapQualitiesAndServices({
    ...merchandizedJobInterests,
    ...supportedServices,
  }).sort();

  const toRate = payRange?.hourlyRateTo?.amount || '0';
  const fromRate = payRange?.hourlyRateFrom?.amount || '0';

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={7}>
        <Box>
          {renderedSubjects.length === 0 ? (
            <AttributeList
              label="Subjects"
              content={`${firstName} hasn't provided this information.`}
            />
          ) : (
            <AttributeList
              label="Subjects"
              content={renderedSubjects}
              icon={<Icon24UtilityCheckmark />}
            />
          )}
        </Box>
        <Box mt={3} mb={{ xs: 3, sm: 0 }} maxWidth={326}>
          <Typography variant="h4" component="p">
            Rates
          </Typography>
          {!fromRate && !toRate ? (
            <Box mt={1}>{firstName} hasn&apos;t provided this information.</Box>
          ) : (
            <Box mt={1}>
              {(Number(fromRate) > 0 || Number(toRate) > 0) && (
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">Recurring jobs</Typography>
                  <Typography variant="h4" component="p">
                    {payRangeHelper(toRate, fromRate)}/hr
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <CanHelpWith firstName={firstName} canHelpWithList={renderedCanHelpWith} />
    </Grid>
  );
};

export { TutoringServices };
