import { Grid, Drawer, Box, Container, IconButton, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Icon24UtilityClose } from '@care/react-icons';
import { OverallRating } from '@care/react-component-lib';
import { ReviewInfoRatingType } from '@/__generated__/globalTypes';
import { MostRecentReviewProps } from './MostRecentReview';
import { ReviewCard } from './ReviewCard';

const ReviewsModal = ({
  openModal,
  setOpenModal,
  reviews,
  averageRatings,
  totalReviews,
}: MostRecentReviewProps) => {
  const isMobileOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const averageRating =
    averageRatings.find((rating) => rating.type === ReviewInfoRatingType.OVERALL) ??
    averageRatings[0];

  const allReviews = () => {
    return reviews.map((review) => {
      return (
        <Box mb={2} data-testid="reviews-modal-review">
          <ReviewCard review={review} />
        </Box>
      );
    });
  };

  return (
    <Drawer
      anchor={isMobileOrSmaller ? 'bottom' : 'right'}
      open={openModal}
      onClose={() => setOpenModal(!openModal)}
      data-testid="modal-drawer"
      sx={(theme) => ({
        '& .MuiDrawer-paper': {
          p: theme.spacing(2, 0, 0, 0),

          [theme.breakpoints.up('md')]: {
            borderRadius: 0,
          },
        },
        '& .MuiDrawer-paperAnchorBottom': {
          maxHeight: '75%',
        },
      })}>
      <Container
        maxWidth="md"
        sx={{
          py: 0,
          px: { xs: 3, md: 4.5 },
        }}>
        <Grid container item xs={12}>
          <Box width="100%" display="flex" justifyContent="flex-end" pb={4}>
            <Box position="fixed">
              <IconButton onClick={() => setOpenModal(!openModal)} size="large">
                <Icon24UtilityClose />
              </IconButton>
            </Box>
          </Box>
          {averageRating && (
            <Box pb={2}>
              <OverallRating
                overallRating={Number(averageRating.value.toFixed(1))}
                totalReviews={totalReviews}
              />
            </Box>
          )}
          <Grid item xs={12}>
            {allReviews()}
          </Grid>
        </Grid>
      </Container>
    </Drawer>
  );
};

export { ReviewsModal };
