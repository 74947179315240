import { Grid, Box, Typography } from '@mui/material';
import {
  IconIllustrationSmallKid,
  IconIllustrationSmallTyke,
  IconIllustrationSmallToddler,
  IconIllustrationSmallBaby,
  IconIllustrationSmallPreteenPlus,
} from '@care/react-icons';
import { ChildCareAgeGroups } from '@/__generated__/globalTypes';
import mapQualitiesAndServices from '@/utils/mapQualitiesAndServices';
import payRangeHelper from '@/utils/payRangeHelper';
import {
  getCaregiver_getCaregiver_profiles_childCareCaregiverProfile as ChildCareProfileType,
  getCaregiver_getCaregiver_profiles_childCareCaregiverProfile_rates as RateType,
  getCaregiver_getCaregiver_profiles_commonCaregiverProfile_merchandizedJobInterests as MerchandizedJobInterestsType,
} from '@/__generated__/getCaregiver';
import { CanHelpWith } from './CanHelpWith';

type ChildCareServicesProps = {
  childCareProfile: ChildCareProfileType;
  merchandizedJobInterests: MerchandizedJobInterestsType;
  firstName: string;
};

const AGE_GROUP_MAP: Record<
  ChildCareAgeGroups,
  {
    icon: React.ReactElement;
    text: string;
  }
> = {
  NEWBORN: {
    icon: <IconIllustrationSmallBaby size="48px" />,
    text: '0-11 mo',
  },
  TODDLER: {
    icon: <IconIllustrationSmallToddler size="48px" />,
    text: '1-3 yrs',
  },
  EARLY_SCHOOL: {
    icon: <IconIllustrationSmallTyke size="48px" />,
    text: '4-5 yrs',
  },
  ELEMENTARY_SCHOOL: {
    icon: <IconIllustrationSmallKid size="48px" />,
    text: '6-11 yrs',
  },
  TEEN: {
    icon: <IconIllustrationSmallPreteenPlus size="48px" />,
    text: '12+ yrs',
  },
};

const ChildCareServices = ({
  childCareProfile,
  merchandizedJobInterests,
  firstName,
}: ChildCareServicesProps) => {
  const { numberOfChildren, ageGroups, rates, recurringRate, supportedServices } = childCareProfile;

  const fromRate = recurringRate?.hourlyRateFrom?.amount;
  const toRate = recurringRate?.hourlyRateTo?.amount;
  const renderedServices = mapQualitiesAndServices({
    ...supportedServices,
    ...merchandizedJobInterests,
  }).sort();

  let renderedRates = [];

  if (!numberOfChildren) {
    renderedRates = rates;
  } else {
    renderedRates = rates.filter((rate: RateType) => {
      return rate.numberOfChildren <= numberOfChildren || rate.isDefaulted === false;
    });
  }

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={7}>
        <Typography variant="h4" component="p">
          Age groups
        </Typography>
        {numberOfChildren === null && ageGroups.length === 0 ? (
          <Box mt={1}>
            <Typography variant="body2">
              Message {firstName} to get details about their experience.
            </Typography>
          </Box>
        ) : (
          <>
            <Box display="flex" textAlign="center" flexWrap="wrap">
              {(ageGroups as ChildCareAgeGroups[]).map((group: ChildCareAgeGroups) => (
                <Box key={group} my={1} mr={{ xs: 2, sm: 3 }} display="flex" flexDirection="column">
                  {AGE_GROUP_MAP[group].icon}
                  <Typography variant="body3" pt={1}>
                    {AGE_GROUP_MAP[group].text}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <Typography variant="body2">
                Willing to care for up to {numberOfChildren}
                {numberOfChildren === 1 ? ' child' : ' children'}
              </Typography>
            </Box>
          </>
        )}
        <Box mt={3} mb={{ xs: 3, sm: 0 }}>
          <Typography variant="h4" component="p" pb={1}>
            Rates
          </Typography>
          {!fromRate && !toRate && rates.length === 0 ? (
            <Box>{firstName} hasn&apos;t provided this information.</Box>
          ) : (
            <Box maxWidth={326}>
              {fromRate && toRate && (Number(fromRate) > 0 || Number(toRate) > 0) && (
                <Box display="flex" justifyContent="space-between" pb={1}>
                  <Typography variant="body2">Recurring jobs</Typography>
                  <Typography variant="h4" component="p">
                    {payRangeHelper(toRate, fromRate)}/hr
                  </Typography>
                </Box>
              )}
              {renderedRates.length > 0 && (
                <Box>
                  <Typography variant="body2" pb={1}>
                    One time jobs
                  </Typography>
                  {renderedRates.map((rate: RateType) => (
                    <Box
                      key={rate.numberOfChildren + rate.hourlyRate.amount}
                      display="flex"
                      justifyContent="space-between"
                      sx={{ ':not(:last-child)': { pb: 1 } }}>
                      <Typography variant="body2" color="secondary">
                        {rate.numberOfChildren === 1
                          ? '1 child'
                          : `${rate.numberOfChildren} children`}
                      </Typography>
                      <Typography variant="h4" component="p">
                        ${rate.hourlyRate.amount}/hr
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <CanHelpWith firstName={firstName} canHelpWithList={renderedServices} />
    </Grid>
  );
};

export { ChildCareServices };
