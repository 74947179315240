import { FC, ForwardedRef, forwardRef } from 'react';
import { ProfileAvatar, Rating, Tag } from '@care/react-component-lib';
import { Box, Grid, Typography } from '@mui/material';
import { ReviewsByReviewee_reviewsByReviewee_ReviewsByRevieweePayload_reviews as ReviewInfo } from '@/__generated__/ReviewsByReviewee';
import { ReviewInfoRatingType } from '@/__generated__/globalTypes';

/**
 * NOTE: This component has been copied directly from the Reviews component in core-caregiver-profile-components
 * repo to be updated specifically for seo-mfe needs.
 * We are defaulting the display name to "Care member" if the display name is not available.
 */

export interface ReviewCardProps {
  review: ReviewInfo;
  /**
   * Optional prop to provide a ref for the IntersectionObserver
   */
  ref?: ForwardedRef<IntersectionObserver>;
}

/**
 * Given a full name (first and last) returns a names' initials
 *
 * @param fullname string
 * @returns string
 */
const getInitialsFromName = (fullname: string): string => {
  if (fullname) {
    const nameBits = fullname.split(' ');

    if (nameBits.length === 1) {
      return `${nameBits[0][0]}`.toUpperCase();
    }

    if (nameBits.length > 1) {
      return `${nameBits[0][0]}${nameBits[nameBits.length - 1][0]}`.toUpperCase();
    }
  }

  return '';
};

/**
 * Renders the `Review` content from a reviewee. This will be used in the CaregiverProfilePage in the
initial page render, and the All Reviews Actionsheet.
 */
const ReviewCard: FC<ReviewCardProps> = forwardRef<IntersectionObserver, ReviewCardProps>(
  ({ review, ...rest }, ref) => {
    const rating = review.ratings.find((obj) => obj.type === ReviewInfoRatingType.OVERALL);
    const reviewDate = new Date(review.createTime).toLocaleDateString();

    const component = (
      <Box {...rest}>
        <Grid container direction="row" alignItems="center">
          <Grid item mr={1}>
            <ProfileAvatar
              size="medium"
              src={review.reviewer.imageURL?.toString() || undefined}
              alt={`Profile image of ${review.reviewer.displayName ?? 'Care member'}`}>
              {getInitialsFromName(review.reviewer.displayName ?? 'Care member')}
            </ProfileAvatar>
          </Grid>
          <Grid item ml={1}>
            <Typography>{review.reviewer.displayName ?? 'Care member'}</Typography>
          </Grid>
        </Grid>
        {(Boolean(rating?.value) || Boolean(review.verifiedByCare)) && (
          <Grid container direction="row" alignItems="center" mt={1}>
            {rating?.value && (
              <Grid item>
                <Rating defaultValue={rating?.value} size="small" readOnly />
              </Grid>
            )}
            {review.verifiedByCare && (
              <Grid item>
                <Tag type="green">Verified Booking by Care</Tag>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container item mt={1}>
          <Typography variant="info1" color="secondary">
            Reviewed on {reviewDate}
          </Typography>
        </Grid>
        <Grid container item mt={1}>
          <Typography variant="body2">{review?.description.originalText}</Typography>
        </Grid>
      </Box>
    );

    return ref ? (
      <Grid container>
        <Box ref={ref}>{component}</Box>
      </Grid>
    ) : (
      component
    );
  }
);

export { ReviewCard };
