import { Box, ClickAwayListener, Grid, Tooltip, Typography, Theme } from '@mui/material';
import { useState } from 'react';
import { Icon24UtilityInfoFilled } from '@care/react-icons';
import { ExpandableBlock } from '@care/caregiver-profile-components';
import { getCaregiver_getCaregiver as CaregiverType } from '@/__generated__/getCaregiver';
import AssistedBioIndicator from '../AssistedBioIndicator';

export type AboutCaregiverProps = {
  experienceSummary?: string | null;
  isVaccinated: CaregiverType['isVaccinated'];
  yearsOfExperience?: number | null;
  isAiAssistedBio: boolean;
};

const AboutCaregiver = ({
  experienceSummary,
  isVaccinated,
  yearsOfExperience,
  isAiAssistedBio,
}: AboutCaregiverProps) => {
  const [openCovidTooltip, setOpenCovidTooltip] = useState(false);

  const handleCovidTooltip = (action: 'open' | 'close') => {
    if ((action === 'open') !== openCovidTooltip) {
      setOpenCovidTooltip(action === 'open');
    }
  };

  const getCovidTooltipHtml = () => {
    return (
      <Typography variant="body3" aria-label="covid-tooltip" data-testid="covid-tooltip-text">
        This caregiver has indicated they are fully vaccinated against COVID-19. The information is
        self-reported and has not been verified by Care.
      </Typography>
    );
  };
  return (
    <Grid item xs={12}>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        direction="row"
        color={(theme: Theme) => theme.palette.care.grey[600]}
        mb={2}>
        {yearsOfExperience && yearsOfExperience >= 0 && (
          <Grid item xs="auto">
            <Box mr={5} alignSelf="center">
              <Typography variant="body3">
                {`Experience: ${yearsOfExperience} ${yearsOfExperience === 1 ? 'yr' : 'yrs'}`}
              </Typography>
            </Box>
          </Grid>
        )}
        {isVaccinated && (
          <ClickAwayListener onClickAway={() => handleCovidTooltip('close')}>
            <Grid
              item
              aria-label="tooltip-focus-area"
              onMouseLeave={() => handleCovidTooltip('close')}
              // maxWidth fixes iOS Safari issue where on tooltip open the whole section would resize and jump
              maxWidth="180px">
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => handleCovidTooltip('close')}
                open={openCovidTooltip}
                disableFocusListener
                disableTouchListener
                disableHoverListener
                title={getCovidTooltipHtml()}
                placement="top"
                arrow>
                <Grid container alignItems="center" data-testid="covid-tooltip">
                  <Typography variant="info1" onClick={() => handleCovidTooltip('open')}>
                    COVID-19 vaccinated
                  </Typography>
                  <Icon24UtilityInfoFilled
                    size="24px"
                    aria-label="covid-badge-icon"
                    onMouseOver={() => handleCovidTooltip('open')}
                    onFocus={() => handleCovidTooltip('open')}
                    data-testid="covid-tooltip-icon"
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </ClickAwayListener>
        )}
      </Grid>
      {experienceSummary && (
        <ExpandableBlock>
          <Typography variant="body2">{experienceSummary}</Typography>
        </ExpandableBlock>
      )}
      {isAiAssistedBio && <AssistedBioIndicator />}
    </Grid>
  );
};

export { AboutCaregiver };
