import { Box, Container, Grid, useMediaQuery, Theme } from '@mui/material';
import { Divider } from '@care/react-component-lib';
import { getSimilarCaregivers_seoSimilarCaregiverProfiles_SEOSimilarCaregiverProfilesSuccess_similarCaregiverProfiles_caregiverList as similarCaregiverProfile } from '@/__generated__/getSimilarCaregivers';
import { SimilarCaregivers } from '../SimilarCaregivers';

const SimilarCaregiverSection = ({ profiles }: { profiles: similarCaregiverProfile[] }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Container maxWidth="md" disableGutters={isDesktop}>
      <Grid container item xs={12} lg={9}>
        <Box component="section" mt={{ xs: 2, lg: 4 }} width="100%">
          <Divider />
          <SimilarCaregivers profiles={profiles} />
        </Box>
      </Grid>
    </Container>
  );
};

export { SimilarCaregiverSection };
