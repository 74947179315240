import { Box, Container, useMediaQuery, Theme } from '@mui/material';
import { CareCheckBox, CareCheckBoxProps } from '@/components/CaregiverProfile/CareCheckBox';

const SafetyCardSection = ({ firstName }: CareCheckBoxProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Box mt={4} mb={{ xs: 0, md: 4 }} component="section">
      <Container maxWidth="md" disableGutters={isDesktop}>
        <Box width={{ xs: '100%', sm: '540px' }}>
          <CareCheckBox firstName={firstName} />
        </Box>
      </Container>
    </Box>
  );
};

export { SafetyCardSection };
